<template>
  <div>
    <div class="slider">
      <div class="slideItem">
        <video autoplay loop muted playsinline webkit-playsinline>
          <source src="assets/video/s1.mp4" type="audio/mp4">
          <source src="assets/video/s1.webm" type="video/webm">
        </video>
      </div>
      <carousel v-if="0>3" :autoplay="10000" :items-to-show="1" :wrap-around="1">
        <slide v-for="slide in 3" :key="slide">
          <div v-if="slide == 1" class="slideItem">
            <video autoplay loop muted
                   poster="https://motionarray.imgix.net/motion-array-1009420-S3Xdy1o162-high_0001.jpg?w=660&amp;q=60&amp;fit=max&amp;auto=format">
              <source src="https://dsqqu7oxq6o1v.cloudfront.net/motion-array-1009420-S3Xdy1o162-high.mp4"
                      type="video/mp4">
              <source src="https://dsqqu7oxq6o1v.cloudfront.net/motion-array-1009420-S3Xdy1o162-high.webm"
                      type="video/webm">
            </video>
          </div>
          <div v-else-if="slide == 2" class="slideItem">
            <video autoplay loop muted
                   poster="https://motionarray.imgix.net/preview-98190-bpYinGv5qi-high_0001.jpg?w=660&amp;q=60&amp;fit=max&amp;auto=format">
              <source src="https://dsqqu7oxq6o1v.cloudfront.net/preview-98190-bpYinGv5qi-high.mp4" type="video/mp4">
              <source src="https://dsqqu7oxq6o1v.cloudfront.net/preview-98190-bpYinGv5qi-high.webm" type="video/webm">
            </video>
          </div>
          <div v-else class="slideItem">
            <video autoplay loop muted
                   poster="https://motionarray.imgix.net/motion-array-1057236-410453_ProresV3-20195_Prores_v422_69804_Fire_And_Metal_4K_GRADED_V1-0021-high_0004.jpg?w=660&amp;q=60&amp;fit=max&amp;auto=format">
              <source
                  src="https://dsqqu7oxq6o1v.cloudfront.net/motion-array-1057236-410453_ProresV3-20195_Prores_v422_69804_Fire_And_Metal_4K_GRADED_V1-0021-high.mp4"
                  type="video/mp4">
              <source
                  src="https://dsqqu7oxq6o1v.cloudfront.net/motion-array-1057236-410453_ProresV3-20195_Prores_v422_69804_Fire_And_Metal_4K_GRADED_V1-0021-high.webm"
                  type="video/webm">
            </video>
          </div>
        </slide>

        <template #addons>
          <navigation/>
        </template>
      </carousel>
    </div>

<!--    <section class="keepOurSector">-->
<!--      <div class="container">-->
<!--        <div class="row align-items-stretch">-->
<!--          <div class="col-12 text-center">-->
<!--            <h2 class="title_head mb-5" style="font-size: 55px">{{ langcontent.sectors }}</h2>-->
<!--          </div>-->
<!--          <div :style="'background-image: url('+sectorData[sector].img+')'" class="col-lg-6 imgDiv"></div>-->
<!--          <div class="col-lg-6">-->
<!--            <div class="sectorWrap">-->
<!--              <div v-for="(item,index) in sectorData.slice()" :id="'sector'+index"-->
<!--                   :class="['sector',(index === sector ? 'acc' : '')]" @mouseenter="sectorSelect(index)">-->
<!--                <img :alt="item.title" :src="item.icon">-->
<!--                <span>{{ item.title }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <h2 class="title_head mt-4">{{ sectorData[sector].title }}</h2>-->
<!--            <p>{{ sectorData[sector].desc }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section class="cons_about_area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="cons_about_img">
              <img :src="about.indeximg" alt="" class="one img-fluid" style="max-width: 70%">
              <img :src="about.indexbg" alt="" class="two img-fluid" style="max-width: 60%">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="cons_about_content">
              <h6 class="title_top">{{ langcontent.corporate }}</h6>
              <h2 class="title_head">Sezersan</h2>
              <div v-html="about.text.substring(0,300)+'...'"></div>
              <router-link class="theme_btn theme_btn_three hover_style1" to="/kurumsal">
                {{ langcontent.ins }} <i class="fas fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FsLightbox
        :sources="video"
        :toggler="toggler"
        slide="1"
    />
    <section class="cons_work_area">
      <div class="cons_work_left">
        <div class="cons_about_content">
          <h6 class="title_top">Sezersan</h6>
          <h2 class="title_head">
            {{ promo.title }}
          </h2>
          <p>
            {{ promo.desc }}</p>
        </div>
      </div>
      <div class="cons_work_right">
        <img :src="promo.img" alt="">
        <a class="video_icon popup-youtube" @click="toggler = !toggler">
          <span><i class="fas fa-play"></i></span>
        </a>
      </div>
    </section>
    <section class="cons_service_area_two pb-0 my-5">
      <div class="container">
        <div class="service_tab mb-0">
          <ul class="nav nav-tabs">
            <li class="nav-item" role="presentation">
              <a class="nav-link active">
                <h2>T</h2>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link active">
                <h2>E</h2>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link active">
                <h2>L</h2>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="cons_mission_aream pt-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="cons_mission_img">
              <img :src="production.img" alt="" class="mission_img_one" style="width: 70%">
              <img :src="production.bgimg" alt="" class="mission_img_two" style="width: 65%">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="cons_about_content mission_content">
              <h6 class="title_top">{{ langcontent.production }} </h6>
              <h2 class="title_head">{{ production.title }}</h2>
              <div v-html="production.desc.substring(0,600)+'...'"></div>
              <router-link class="theme_btn theme_btn_three hover_style1" to="/uretim">{{ langcontent.ins }} <i
                  class="fas fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cons_team_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-5">
            <div class="cons_about_content">
              <h6 class="title_top">{{ langcontent.quality }} </h6>
              <h2 class="title_head">{{ quality.title }}</h2>
              <p>
                {{ quality.desc }}
              </p>
            </div>
          </div>
          <div class="col-lg-8 col-md-7">
            <div class="team_img_info">
              <img alt="" class="dot_middle" src="assets/img/qbg.png">
              <div class="row">
                <div class="col-6 cons_team_col">
                  <a class="cons_team_item" data-parallax='{"x": 0, "y": 50}'>
                    <img :src="quality.img" alt="" style="width: 100%;">
                  </a>
                </div>
                <div class="col-6 cons_team_col">
                  <a class="cons_team_item" data-parallax='{"x": 0, "y": -50}'>
                    <img :src="quality.img2" alt="" style="width: 100%;">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iso from "../../axios";
import axios from "../../axios";
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
import FsLightbox from "fslightbox-vue/v3";

export default {
  components: {
    FsLightbox,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      about: [],
      sectorData: [],
      quality: [],
      production: [],
      promo: [],
      video: [],
      sector: 0,
      toggler: false,
      langcontent: [],
    }
  },
  created() {
    axios.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('sector')
        .then(response => {
          this.sectorData = response.data
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('corporate')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('promo')
        .then(response => {
          this.promo = response.data[0]
          this.video.push(response.data[0].url)
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('production')
        .then(response => {
          this.production = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('quality')
        .then(response => {
          this.quality = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })

  },
  methods: {
    sectorSelect: function (e) {
      this.sector = e
      document.querySelector('.acc').classList.remove('acc')
      document.getElementById('sector' + e).classList.add('acc')
    },
    // tStart: function () {
    //   alert('ts')
    //   document.getElementById('video').play();
    // @touchstart="tStart"
    // }
  }
}
</script>
<style>
.slider {
  width: 100vw;
  height: 100vh;
}

.slideItem {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

/*.slideItem:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*}*/

.slideItem video {
  position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    transform: translateX(calc((100% - 100vw) / 2));
}

.carousel__prev {
  left: 50px;
}

.carousel__next {
  right: 50px;
}

.sliderWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 100px 50px 50px 50px;
  width: 100vw;
  height: 100vh;
  position: relative;
  background: var(--bg);
}

.sliderWrapper .textContent {
  flex: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.sliderWrapper .textContent div {
  max-width: 30vw;
  position: relative;
}

.sliderWrapper .textContent div .out {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 900;
  line-height: 80px;
  font-size: 100px;
}

.sliderWrapper .textContent div .desc {
  padding-top: 40px;
  opacity: .8;
}

.sliderWrapper .imgContent {
  flex: 1;
}

.sliderWrapper .imgContent > div {
  position: relative;
  display: block;
  width: 670px;
  height: 680px;

}

.sliderWrapper .imgContent > div > img {
  position: absolute;
  bottom: 0;
  left: -15px;
  height: auto;
  z-index: 2;
  width: 700px;
  animation: moves 10s infinite ease;
}

.sliderWrapper .imgContent > div .bgImg {
  width: 670px;
  height: 680px;
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
  background: var(--bg) center repeat-y;
  background-size: 100% auto;
  background-position: 0 var(--pos);
}

.sliderWrapper .imgContent > div .bgImgTopLayer {
  position: absolute;
  bottom: 0;
  left: -15px;
  height: auto;
  z-index: -1;
  width: 700px;
  animation: moves 10s infinite ease;
}

@keyframes moves {
  0% {
    bottom: -40px;
  }
  25% {
    bottom: -20px;
  }
  50% {
    bottom: -40px;
  }
  75% {
    bottom: -20px;
  }
  100% {
    bottom: -40px;
  }
}

@media screen and ( max-width: 1099px) {
  .sliderWrapper .textContent div .desc {
    display: none;
  }

  .sliderWrapper .textContent div .out {
    text-align: center;
    width: 100vw;
    font-size: 70px;
  }

  .sliderWrapper .textContent div {

    max-width: 100vw;
  }

  .sliderWrapper .imgContent {
    display: flex;
    width: 100vw;
    justify-content: center;
  }
}

@media screen and ( max-width: 700px) {
  .sliderWrapper .textContent div {
    width: 100vw;
    position: absolute;
    top: 120px;
  }

  .sliderWrapper .textContent div .out {
    font-size: 30px;
  }

  .sliderWrapper .imgContent div {
    transform: scale(.7);
  }

  .sliderWrapper .imgContent > div > img {
    transform: scale(.7);
  }
}

.keepOurSector {
  margin-top: 50px;
}

.keepOurSector .imgDiv {
  background-color: #cccccc;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

.keepOurSector .sectorWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 10px;
}

.keepOurSector .sectorWrap .sector {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  width: calc((100% - 20px) / 3);
  text-align: center;
  flex-grow: 1;
  grid-gap: 20px;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  padding-top: 20px;
}

.keepOurSector .sectorWrap .sector img {
  max-height: 100px;
  width: auto;
  filter: invert(.6);
  position: relative;
  display: block;
}

.keepOurSector .sectorWrap .sector:after {
  content: '';
  position: absolute;
  top: 135px;
  width: 40%;
  height: 1px;
  left: 30%;
  background-color: #fff;
  transition: all ease .3s;
}

.keepOurSector .sectorWrap .sector span {
  padding-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.keepOurSector .sectorWrap .sector.acc,
.keepOurSector .sectorWrap .sector:hover {
  background-color: #071897;
}

.keepOurSector .sectorWrap .sector.acc img,
.keepOurSector .sectorWrap .sector:hover img {
  filter: invert(1);
}

.keepOurSector .sectorWrap .sector.acc:after,
.keepOurSector .sectorWrap .sector:hover:after {
  width: 90%;
  left: 5%;
}

</style>
