import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home,
    meta: {
      header: 'home',
    }
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal',
    component: () => import('../components/views/About'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/urunler',
    name: 'Ürünler',
    component: () => import('../components/views/Products'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/urun/:product',
    name: 'Ürün',
    component: () => import('../components/views/ProductDetail'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/page/:page',
    name: 'Sayfa',
    component: () => import('../components/views/Page'),
    meta: {
      header: 'custom'
    }
  },
  {
    path: '/kalitee',
    name: 'Kalite',
    component: () => import('../components/views/Quality'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/uretim',
    name: 'Üretim',
    component: () => import('../components/views/Production'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/yonet',
    name: 'Admin',
    component: () => import('../components/views/Admin'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../components/views/Contact'),
    meta: {
      header: 'default'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next)=>{
  document.title = 'Sezersan Tel | '+to.name
  next()
})
export default router

