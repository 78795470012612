<template>
  <div id="preloader">
    <div class="product_name">Sezersan Tel</div>
  </div>

  <appHeader></appHeader>
    <router-view ></router-view>
  <appFooter ></appFooter>
</template>
<script>
import HeaderVue from "./components/views/common/Header";
import FooterVue from "./components/views/common/Footer";
// import iso from "./axios";
export default {
  components : {
    "appHeader" : HeaderVue,
    "appFooter" : FooterVue,
  },
  data(){
    return{
      // langcontent:['null']
  }
  },
  created() {
    // iso.get('langfix')
    //     .then(response => {
    //       this.langcontent = response.data[0]
    //       console.log(this.langcontent)
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     })
  }
}

</script>