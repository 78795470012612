<template>

  <footer class="footer_area">
    <div class="footer_top">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-lg-left col-lg-3">
            <div class="f_widget f_about_widget">
              <router-link to="/" class="f_logo">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 408.11 78.02" style="enable-background:new 0 0 408.11 78.02;width: 250px" xml:space="preserve">
                  <g>
	<g>
		<path class="st0" d="M40.74,77.01c4.2-4.07,7.67-8.13,6.5-16.71c-1.54-8.1-5.78-14.07-9.07-21.27c-2.73-5.86-3.9-11.49-2.81-16.8
			c1.16-5.04,4.8-9.67,11.18-14.2C51.71,4.41,56.76,2.18,61.81,0c-3.73,2.64-7.46,5.55-11.19,10.22c-2.83,3.68-4.48,7.86-4.97,12.55
			c-0.72,6.81,1.98,13.15,3.62,19.29c1.6,4.69,4.47,12.18,3.24,19.72C50.77,68.33,46.53,73.2,40.74,77.01"/>
    <path class="st0" d="M20.98,77.13C16.8,73.06,13.34,69,14.5,60.41c1.53-8.1,5.75-14.07,9.04-21.28c2.71-5.86,3.88-11.49,2.79-16.8
			c-1.15-5.04-4.78-9.67-11.13-14.2C10.06,4.53,5.03,2.3,0,0.12c3.71,2.64,7.43,5.55,11.15,10.22c2.82,3.68,4.46,7.86,4.95,12.55
			c0.72,6.81-1.98,13.15-3.61,19.29C10.9,46.87,8.04,54.36,9.27,61.9C10.99,68.45,15.21,73.31,20.98,77.13"/>
    <path class="st0" d="M30.88,78.02c8.3,0,15.08-6.77,15.08-15.06c0-8.3-6.77-15.07-15.08-15.07c-8.3,0-15.08,6.77-15.08,15.07
			C15.81,71.25,22.58,78.02,30.88,78.02"/>
	</g>
                    <g>
		<path class="st0" d="M135.7,54.04c1.3-0.6,2.3-1.44,3.03-2.5l-2.66,10.37c-0.43,1.02-1.42,2.02-2.95,2.99
			c-0.82,0.37-1.68,0.56-2.59,0.56h-16.28c-0.05,0-0.31-0.02-0.79-0.07c-3.46-0.33-5.74-1-6.84-2.02c-2.11-1.9-3.17-5.45-3.17-10.64
			c0-6.91,1.56-13.96,4.68-21.15c2.26-5.19,4.71-8.35,7.35-9.46c2.06-0.88,6.12-1.32,12.17-1.32h0.65c6.34,0,10.49,0.44,12.46,1.32
			c2.78,1.25,4.18,4.31,4.18,9.18v0.77c0,1.9-0.36,4.43-1.08,7.58c-0.29,1.34-0.6,2.48-0.94,3.41c-0.1,0.97-1.25,1.46-3.46,1.46
			l-23.63-0.07c0-0.09,0.07-0.44,0.22-1.04c0.19-0.65,0.31-1,0.36-1.04V42.3l0.15-0.69c0.14-0.42,0.31-1.04,0.5-1.88v-0.14
			c0.05-0.05,0.07-0.09,0.07-0.14c0.14-0.56,0.29-1.04,0.43-1.46l0.22-0.63l16.14-0.07c0.38-1.21,0.58-2.39,0.58-3.55
			c0-3.38-2.3-5.08-6.91-5.08c-2.59,0-4.64,0.65-6.13,1.95c-1.25,1.07-2.23,2.62-2.95,4.66c-0.43,1.3-1.06,3.27-1.87,5.91
			c-1.15,5.47-1.73,8.93-1.73,10.37c0,1.76,0.65,2.83,1.94,3.2c0.96,0.28,1.85,0.42,2.67,0.42h0.72l8.21-0.07
			c0.09,0.05,0.19,0.07,0.29,0.07H129c3.03,0,5.26-0.35,6.7-1.04V54.04"/>
                      <path class="st0" d="M154.07,25.79l1.08-5.22h30.47l-2.38,6.89l-24.13,30.19h15.27c0.48,0.05,1.03-0.04,1.66-0.28
			c0.82-0.42,1.37-0.97,1.66-1.67l-2.02,8.28c0,0.56-0.26,0.93-0.79,1.11c-0.34,0.23-1.08,0.35-2.23,0.35l-25.93,0.07
			c-1.53,0-2.4-0.25-2.59-0.76c-0.24-0.23-0.36-0.58-0.36-1.04v-0.14l1.8-5.91l24.71-30.19h-13.33c-0.29,0.05-0.77,0-1.44-0.14
			c-0.67-0.19-1.01-0.4-1.01-0.63c-0.19-0.09-0.34-0.37-0.43-0.83v-0.08"/>
                      <path class="st0" d="M216.23,54.04c1.3-0.6,2.3-1.44,3.03-2.5l-2.67,10.37c-0.43,1.02-1.42,2.02-2.95,2.99
			c-0.82,0.37-1.68,0.56-2.59,0.56h-16.28c-0.05,0-0.31-0.02-0.79-0.07c-3.46-0.33-5.74-1-6.84-2.02c-2.11-1.9-3.17-5.45-3.17-10.64
			c0-6.91,1.56-13.96,4.68-21.15c2.26-5.19,4.71-8.35,7.35-9.46c2.06-0.88,6.12-1.32,12.17-1.32h0.65c6.34,0,10.49,0.44,12.46,1.32
			c2.79,1.25,4.18,4.31,4.18,9.18v0.77c0,1.9-0.36,4.43-1.08,7.58c-0.29,1.34-0.6,2.48-0.94,3.41c-0.1,0.97-1.25,1.46-3.46,1.46
			l-23.63-0.07c0-0.09,0.07-0.44,0.22-1.04c0.19-0.65,0.31-1,0.36-1.04V42.3l0.14-0.69c0.14-0.42,0.31-1.04,0.5-1.88v-0.14
			c0.05-0.05,0.07-0.09,0.07-0.14c0.14-0.56,0.29-1.04,0.43-1.46l0.22-0.63l16.14-0.07c0.38-1.21,0.58-2.39,0.58-3.55
			c0-3.38-2.3-5.08-6.91-5.08c-2.59,0-4.64,0.65-6.12,1.95c-1.25,1.07-2.23,2.62-2.95,4.66c-0.43,1.3-1.06,3.27-1.87,5.91
			c-1.15,5.47-1.73,8.93-1.73,10.37c0,1.76,0.65,2.83,1.95,3.2c0.96,0.28,1.85,0.42,2.67,0.42h0.72l8.21-0.07
			c0.09,0.05,0.19,0.07,0.29,0.07h0.29c3.03,0,5.26-0.35,6.7-1.04v-0.09"/>
                      <path class="st0" d="M236.69,21.48c-0.1-0.09-0.17-0.19-0.22-0.28c-0.24-0.37-0.6-0.56-1.08-0.56l11.02-0.07
			c0.14,0,0.36,0.02,0.65,0.07s0.58,0.14,0.86,0.28c0.1,0.05,0.24,0.07,0.43,0.07c0.63,0.32,1.01,1.11,1.15,2.36v1.46
			c0,0.19-0.02,0.33-0.07,0.42l1.66-3.13l0.43-0.42c0.1-0.09,0.27-0.21,0.51-0.35c0.1-0.09,0.22-0.16,0.36-0.21l0.29-0.14
			c0.05,0,0.12-0.02,0.22-0.07l0.29-0.14h0.22c0.1-0.09,0.24-0.14,0.43-0.14l11.38-0.07l-1.58,5.91c0,0.42-0.19,0.93-0.58,1.53
			c-0.05,0.05-0.1,0.12-0.14,0.21c-0.05,0.05-0.1,0.09-0.14,0.14l-0.29,0.28c-0.67,0.65-1.42,0.97-2.23,0.97l-8.28-0.07
			c-1.25,0-2.26,0.23-3.02,0.7c-0.05,0.05-0.17,0.12-0.36,0.21c-0.15,0.09-0.24,0.16-0.29,0.21L248,30.94
			c-0.53,0.42-0.91,1.09-1.15,2.02l-7.06,29.15c-0.05,0.19-0.07,0.33-0.07,0.42v0.28c0,0.47,0.1,0.84,0.29,1.11
			c0,0.19,0.05,0.28,0.14,0.28v0.14c0.43,0.74,1.08,1.11,1.94,1.11h-11.6c-1.44,0-2.28-0.28-2.52-0.84
			c-0.29-0.32-0.48-0.83-0.58-1.53v-0.35l9.29-38.68c0.24-0.93,0.36-1.46,0.36-1.6v-0.28c-0.1-0.14-0.22-0.35-0.36-0.63v-0.06"/>
                      <path class="st0" d="M100.18,20.51l-1.37,5.35c-0.23,0.87-0.73,1.61-1.24,2.05c-1.02,1-2.3,1.2-3.44,1.18l-8.72-0.01
			c-0.35,0-1.16,0.02-2.05,0.03c-1.97,0.04-3.44,0.19-4.13,0.49c-0.66,0.29-1.91,1.01-2.28,2.56c-0.23,0.97-0.21,1.53-0.13,2.03
			c0.28,1.66,3.21,2.04,6.05,2.76l4.78,1.21c3.44,0.9,6.85,1.81,8.48,3.88c0.87,1.11,1.31,2.09,1.45,4.5
			c0.2,3.36-0.55,8.05-2.7,11.44c-1.67,2.63-3.31,4.45-5.57,5.63c-2.3,1.2-4.9,1.85-7.65,1.85l-22.3,0.01
			c-1.04,0-2.11-0.53-2.46-1.53c-0.07-0.28-0.06-0.58,0-0.88l2.59-10.07h1.41c0.01,0.38,0.42,0.97,0.48,1.05
			c0.15,0.2,0.33,0.37,0.56,0.56c0.27,0.23,0.53,0.41,0.89,0.62c0.19,0.09,0.38,0.17,0.57,0.21c0.8,0.23,1.66,0.31,2.56,0.35h0.96
			c2.87,0,3.15,0,4.88,0c5.87,0,8.8,0.21,10.74-1.36c0.88-0.71,1.36-1.39,1.58-2.33c0.12-0.5,0.17-1.21,0.16-1.75
			c-0.08-1.81-1.79-2.01-3.27-2.53c-0.86-0.3-2.5-0.75-4.67-1.2c-3.52-0.73-6.63-1.43-8.92-2.54c-2.05-1-3.96-2.99-4.07-5.5
			l-0.04-0.91c-0.19-4.4,1.11-7.84,2.43-10.09c0.69-1.18,1.98-2.62,3.32-3.71c0.43-0.35,1.53-1.09,2.21-1.48
			c2.01-1.14,4.64-1.76,7.44-1.86l0.52-0.02h20.95"/>
                      <path class="st0" d="M135.7,54.04c1.3-0.6,2.3-1.44,3.03-2.5l-2.66,10.37c-0.43,1.02-1.42,2.02-2.95,2.99
			c-0.82,0.37-1.68,0.56-2.59,0.56h-16.28c-0.05,0-0.31-0.02-0.79-0.07c-3.46-0.33-5.74-1-6.84-2.02c-2.11-1.9-3.17-5.45-3.17-10.64
			c0-6.91,1.56-13.96,4.68-21.15c2.26-5.19,4.71-8.35,7.35-9.46c2.06-0.88,6.12-1.32,12.17-1.32h0.65c6.34,0,10.49,0.44,12.46,1.32
			c2.78,1.25,4.18,4.31,4.18,9.18v0.77c0,1.9-0.36,4.43-1.08,7.58c-0.29,1.34-0.6,2.48-0.94,3.41c-0.1,0.97-1.25,1.46-3.46,1.46
			l-23.63-0.07c0-0.09,0.07-0.44,0.22-1.04c0.19-0.65,0.31-1,0.36-1.04V42.3l0.15-0.69c0.14-0.42,0.31-1.04,0.5-1.88v-0.14
			c0.05-0.05,0.07-0.09,0.07-0.14c0.14-0.56,0.29-1.04,0.43-1.46l0.22-0.63l16.14-0.07c0.38-1.21,0.58-2.39,0.58-3.55
			c0-3.38-2.3-5.08-6.91-5.08c-2.59,0-4.64,0.65-6.13,1.95c-1.25,1.07-2.23,2.62-2.95,4.66c-0.43,1.3-1.06,3.27-1.87,5.91
			c-1.15,5.47-1.73,8.93-1.73,10.37c0,1.76,0.65,2.83,1.94,3.2c0.96,0.28,1.85,0.42,2.67,0.42h0.72l8.21-0.07
			c0.09,0.05,0.19,0.07,0.29,0.07H129c3.03,0,5.26-0.35,6.7-1.04V54.04"/>
                      <path class="st0" d="M154.07,25.79l1.08-5.22h30.47l-2.38,6.89l-24.13,30.19h15.27c0.48,0.05,1.03-0.04,1.66-0.28
			c0.82-0.42,1.37-0.97,1.66-1.67l-2.02,8.28c0,0.56-0.26,0.93-0.79,1.11c-0.34,0.23-1.08,0.35-2.23,0.35l-25.93,0.07
			c-1.53,0-2.4-0.25-2.59-0.76c-0.24-0.23-0.36-0.58-0.36-1.04v-0.14l1.8-5.91l24.71-30.19h-13.33c-0.29,0.05-0.77,0-1.44-0.14
			c-0.67-0.19-1.01-0.4-1.01-0.63c-0.19-0.09-0.34-0.37-0.43-0.83v-0.08"/>
                      <path class="st0" d="M216.23,54.04c1.3-0.6,2.3-1.44,3.03-2.5l-2.67,10.37c-0.43,1.02-1.42,2.02-2.95,2.99
			c-0.82,0.37-1.68,0.56-2.59,0.56h-16.28c-0.05,0-0.31-0.02-0.79-0.07c-3.46-0.33-5.74-1-6.84-2.02c-2.11-1.9-3.17-5.45-3.17-10.64
			c0-6.91,1.56-13.96,4.68-21.15c2.26-5.19,4.71-8.35,7.35-9.46c2.06-0.88,6.12-1.32,12.17-1.32h0.65c6.34,0,10.49,0.44,12.46,1.32
			c2.79,1.25,4.18,4.31,4.18,9.18v0.77c0,1.9-0.36,4.43-1.08,7.58c-0.29,1.34-0.6,2.48-0.94,3.41c-0.1,0.97-1.25,1.46-3.46,1.46
			l-23.63-0.07c0-0.09,0.07-0.44,0.22-1.04c0.19-0.65,0.31-1,0.36-1.04V42.3l0.14-0.69c0.14-0.42,0.31-1.04,0.5-1.88v-0.14
			c0.05-0.05,0.07-0.09,0.07-0.14c0.14-0.56,0.29-1.04,0.43-1.46l0.22-0.63l16.14-0.07c0.38-1.21,0.58-2.39,0.58-3.55
			c0-3.38-2.3-5.08-6.91-5.08c-2.59,0-4.64,0.65-6.12,1.95c-1.25,1.07-2.23,2.62-2.95,4.66c-0.43,1.3-1.06,3.27-1.87,5.91
			c-1.15,5.47-1.73,8.93-1.73,10.37c0,1.76,0.65,2.83,1.95,3.2c0.96,0.28,1.85,0.42,2.67,0.42h0.72l8.21-0.07
			c0.09,0.05,0.19,0.07,0.29,0.07h0.29c3.03,0,5.26-0.35,6.7-1.04v-0.09"/>
                      <path class="st0" d="M236.69,21.48c-0.1-0.09-0.17-0.19-0.22-0.28c-0.24-0.37-0.6-0.56-1.08-0.56l11.02-0.07
			c0.14,0,0.36,0.02,0.65,0.07s0.58,0.14,0.86,0.28c0.1,0.05,0.24,0.07,0.43,0.07c0.63,0.32,1.01,1.11,1.15,2.36v1.46
			c0,0.19-0.02,0.33-0.07,0.42l1.66-3.13l0.43-0.42c0.1-0.09,0.27-0.21,0.51-0.35c0.1-0.09,0.22-0.16,0.36-0.21l0.29-0.14
			c0.05,0,0.12-0.02,0.22-0.07l0.29-0.14h0.22c0.1-0.09,0.24-0.14,0.43-0.14l11.38-0.07l-1.58,5.91c0,0.42-0.19,0.93-0.58,1.53
			c-0.05,0.05-0.1,0.12-0.14,0.21c-0.05,0.05-0.1,0.09-0.14,0.14l-0.29,0.28c-0.67,0.65-1.42,0.97-2.23,0.97l-8.28-0.07
			c-1.25,0-2.26,0.23-3.02,0.7c-0.05,0.05-0.17,0.12-0.36,0.21c-0.15,0.09-0.24,0.16-0.29,0.21L248,30.94
			c-0.53,0.42-0.91,1.09-1.15,2.02l-7.06,29.15c-0.05,0.19-0.07,0.33-0.07,0.42v0.28c0,0.47,0.1,0.84,0.29,1.11
			c0,0.19,0.05,0.28,0.14,0.28v0.14c0.43,0.74,1.08,1.11,1.94,1.11h-11.6c-1.44,0-2.28-0.28-2.52-0.84
			c-0.29-0.32-0.48-0.83-0.58-1.53v-0.35l9.29-38.68c0.24-0.93,0.36-1.46,0.36-1.6v-0.28c-0.1-0.14-0.22-0.35-0.36-0.63v-0.06"/>
                      <path class="st0" d="M303.16,20.51l-1.37,5.35c-0.23,0.87-0.73,1.61-1.23,2.05c-1.02,1-2.3,1.2-3.44,1.18l-8.72-0.01
			c-0.35,0-1.16,0.02-2.04,0.03c-1.97,0.04-3.44,0.19-4.13,0.49c-0.66,0.29-1.9,1.01-2.28,2.56c-0.24,0.97-0.21,1.53-0.13,2.03
			c0.27,1.66,3.21,2.04,6.05,2.76l4.78,1.21c3.44,0.9,6.85,1.81,8.48,3.88c0.87,1.11,1.31,2.09,1.45,4.5
			c0.19,3.36-0.55,8.05-2.7,11.44c-1.67,2.63-3.31,4.45-5.57,5.63c-2.3,1.2-4.9,1.85-7.65,1.85l-22.31,0.01
			c-1.04,0-2.11-0.53-2.46-1.53c-0.07-0.28-0.06-0.58,0-0.88l2.59-10.07h1.41c0.01,0.38,0.42,0.97,0.48,1.05
			c0.15,0.2,0.33,0.37,0.56,0.56c0.27,0.23,0.53,0.41,0.89,0.62c0.19,0.09,0.38,0.17,0.58,0.21c0.79,0.23,1.66,0.31,2.55,0.35h0.96
			c2.87,0,3.15,0,4.88,0c5.87,0,8.8,0.21,10.74-1.36c0.88-0.71,1.36-1.39,1.58-2.33c0.12-0.5,0.17-1.21,0.16-1.75
			c-0.09-1.81-1.79-2.01-3.27-2.53c-0.86-0.3-2.5-0.75-4.67-1.2c-3.51-0.73-6.63-1.43-8.92-2.54c-2.05-1-3.96-2.99-4.07-5.5
			l-0.04-0.91c-0.19-4.4,1.11-7.84,2.43-10.09c0.69-1.18,1.98-2.62,3.32-3.71c0.43-0.35,1.53-1.09,2.21-1.48
			c2.01-1.14,4.64-1.76,7.44-1.86l0.52-0.02h20.94"/>
                      <path class="st0" d="M317.85,25.79l0.79-5.08h23.05c3.84,0,6.31,1.32,7.42,3.96c0.34,0.79,0.55,1.58,0.65,2.37v0.56
			c0,0.42-0.12,1.16-0.36,2.23l-6.48,28.94c-0.34,1.48-0.51,2.62-0.51,3.41c0,0.93,0.12,1.67,0.36,2.23l0.14,0.07v0.14
			c0.29,0.42,0.67,0.67,1.15,0.76c0.1,0.05,0.19,0.07,0.29,0.07h-13.18c-0.05,0-0.41-0.16-1.08-0.49l-0.22-0.21
			c-0.05-0.04-0.12-0.23-0.22-0.56c-0.29-0.56-0.43-1.02-0.43-1.39l4.83-19.83h-9.01c-1.92,0-3.29,0.49-4.11,1.46
			c-0.19,0.23-0.6,0.88-1.23,1.95c-1.1,2.69-1.66,4.64-1.66,5.84c0,0.93,0.24,1.62,0.72,2.09c0.19,0.14,0.43,0.28,0.72,0.42
			c0.14,0.14,0.26,0.21,0.36,0.21l0.29,0.14c0.1,0.04,0.19,0.07,0.29,0.07l11.02-0.07l-4.68,7.65c-0.05,0.14-0.12,0.25-0.22,0.35
			l-0.29,0.28c-0.09,0.14-0.19,0.26-0.29,0.35l-0.29,0.28c-0.29,0.19-0.5,0.35-0.65,0.49l-0.36,0.14c-0.14,0.09-0.26,0.19-0.36,0.28
			c-0.63,0.37-1.13,0.56-1.51,0.56h-9.87c-0.96,0-1.7-0.14-2.23-0.42c-2.93-0.88-4.39-3.55-4.39-8c0-3.53,0.79-7.61,2.38-12.25
			c0.82-2.41,1.56-4.22,2.23-5.43c1.25-2.18,2.66-3.48,4.25-3.9c1.63-0.42,3.22-0.62,4.75-0.62h13.97c0.05,0.04,0.12,0.07,0.22,0.07
			h0.79l0.43-0.14c0.1,0,0.22-0.02,0.36-0.07l0.43-0.14c0.58-0.42,0.91-1.09,1.01-2.02v-0.56c0-0.14,0.02-0.3,0.07-0.49v-0.35
			c0-1.67-0.94-2.5-2.81-2.5l-13.18-0.07c-0.53,0.05-1.25-0.14-2.16-0.56c-0.09,0-0.19-0.05-0.29-0.14
			c-0.48-0.19-0.77-0.67-0.87-1.46c-0.05-0.09-0.07-0.18-0.07-0.28v-0.34"/>
                      <path class="st0" d="M358.63,21.83l-0.14-0.21c-0.1-0.09-0.19-0.21-0.29-0.35l-0.29-0.28c-0.14-0.09-0.34-0.23-0.58-0.42
			l11.17-0.07c0.19,0.05,0.48,0.16,0.86,0.35h0.15c0,0.05,0.05,0.09,0.14,0.14c0.1,0,0.31,0.09,0.65,0.28
			c0.86,0.47,1.3,1.16,1.3,2.09l-0.36,2.85l2.3-3.48c0.1-0.18,0.46-0.56,1.08-1.11c0.1-0.09,0.19-0.16,0.29-0.21l0.29-0.14
			c0.29-0.09,0.7-0.25,1.23-0.49c0.29-0.05,0.69-0.12,1.23-0.21l8.79-0.07c2.11,0,3.87,0.63,5.26,1.88
			c1.39,1.21,2.09,2.83,2.09,4.87c0,0.74-0.17,1.81-0.5,3.2l-7.64,31.1c0,1.07,0.34,2.09,1.01,3.06l0.65,0.63
			c0.29,0.14,0.46,0.21,0.5,0.21H375c-0.29-0.09-0.55-0.25-0.79-0.49c-0.05-0.09-0.1-0.16-0.14-0.21l-0.14-0.14
			c-0.19-0.18-0.29-0.58-0.29-1.18v-0.28c0.1-0.19,0.15-0.37,0.15-0.56l6.99-29.5c-0.1-1.25-0.29-2.04-0.58-2.36
			c-0.34-0.7-1.83-1.04-4.47-1.04h-0.22c-0.24,0-0.34,0-0.29,0c-1.73,0.09-2.98,0.35-3.75,0.76c-0.86,0.23-1.49,1.09-1.87,2.57
			l-7.64,29.5c0,0.93,0.17,1.55,0.5,1.88l0.14,0.07c0,0.05,0.02,0.09,0.07,0.14l0.36,0.35c0.34,0.32,0.74,0.49,1.23,0.49H351.8
			c-0.72,0-1.32-0.21-1.8-0.63c-0.48-0.42-0.67-0.97-0.57-1.67v-0.42l9.58-38.61c0-0.65-0.12-1.39-0.36-2.23v-0.06"/>
                      <path class="st0" d="M402.16,15.34c0.05,0,0.1,0.01,0.15,0.01c0.46,0.04,0.83,0.15,1.13,0.33c0.23,0.14,0.56,0.44,0.7,0.76
			c0.15,0.32,0.17,0.5,0.16,0.88c-0.01,0.47-0.19,0.79-0.45,1.12c-0.26,0.32-0.59,0.44-0.85,0.56v0.04c0.2,0.07,0.37,0.2,0.52,0.36
			c0.19,0.22,0.37,0.55,0.49,1.05c0.17,0.63,0.28,1.32,0.51,1.9h-0.91c-0.19-0.48-0.29-0.85-0.41-1.42
			c-0.14-0.94-0.51-1.42-1.05-1.55v-0.74c0.27-0.04,0.57-0.17,0.71-0.27c0.19-0.13,0.32-0.31,0.41-0.5s0.12-0.37,0.12-0.59
			c0-0.23-0.07-0.53-0.25-0.74c-0.13-0.16-0.34-0.41-0.99-0.49v-0.71"/>
                      <path class="st0" d="M400.03,22.34l-0.01-6.86c0.65-0.09,1.39-0.18,2.14-0.14v0.7c-0.21-0.02-0.48-0.03-0.8,0
			c-0.15,0.01-0.36,0.02-0.43,0.09v2.51h1.08c0.05,0,0.1,0,0.16-0.01v0.73c-0.12-0.03-0.24-0.04-0.37-0.04h-0.87l0.01,3.02
			L400.03,22.34"/>
                      <path class="st0" d="M401.95,12.51c3.4,0,6.16,2.8,6.16,6.24s-2.76,6.24-6.16,6.24v-1.03c2.85,0,5.16-2.33,5.16-5.2
			s-2.31-5.2-5.16-5.2V12.51"/>
                      <path class="st0" d="M401.95,12.51L401.95,12.51v1.06c-2.85,0-5.16,2.33-5.16,5.2s2.31,5.2,5.16,5.2V25l0,0
			c-3.4,0-6.16-2.79-6.16-6.24C395.78,15.3,398.54,12.51,401.95,12.51"/>
	</g>
</g>
</svg>
              </router-link>
              <p>
                {{ langcontent.foottext }}
              </p>
              <ul class="list-unstyled f_social">
                <li><a :href="data.facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a :href="data.twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a :href="data.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-6 text-center text-lg-left col-lg-3">
            <div class="f_widget f_link_widget">
              <h3 class="f_title">{{ langcontent.menu }}</h3>
              <ul class="list-unstyled f_link">
                <li><router-link class="nav-link" to="/">{{ langcontent.home }}</router-link></li>
                <li><router-link class="nav-link" to="/kurumsal">{{ langcontent.corporate }}</router-link></li>
                <li><router-link class="nav-link" to="/uretim">{{ langcontent.production }}</router-link></li>
                  <template v-for="page in pages">
                 <li><router-link class="nav-link" :to="'/page/'+page.seo">{{ page.name }}</router-link></li>
                </template>
                <li><router-link class="nav-link" to="/iletisim">{{ langcontent.contact }}</router-link></li>
<!--                <li><router-link class="nav-link" to="/kalite">Kalite</router-link></li>-->
              </ul>
            </div>
          </div>
          <div class="col-6 text-center text-lg-left col-lg-3 ">
            <div class="f_widget f_link_widget">
              <h3 class="f_title">{{ langcontent.products }}</h3>
              <ul class="list-unstyled f_link">
                <li v-for="item in products.slice(0,3)"><router-link class="nav-link" :to="'/urun/'+item.seo">{{ item.name }}</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-12 text-center text-lg-left col-lg-3 ">
            <div class="f_widget f_contact_info_widget">
              <h3 class="f_title">{{langcontent.contact}}</h3>
              <ul class="list-unstyled contact_info_link">
                <li><b>{{ langcontent.address1 }}:</b> <span> {{ data.addr }}</span></li>

                <li><b>{{ langcontent.phone }}:</b> <a target="_blank" :href="'tel:'+data.phone">{{ data.phone }}</a></li>
                <li><b>{{ langcontent.mail }}:</b> <a target="_blank" :href="'mailto:'+data.mail">{{ data.mail }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom border_top">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-7">
            <p class="copy_text">© {{ langcontent.copy }}</p>
          </div>
          <div class="col-sm-5 text-right">
            <a href="//gifadwork.com" class="text-white" target="_blank">Gif Adwork</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style>
.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
</style>
<script>
import iso from "../../../axios";
import axios from "../../../axios";
export default {
  data(){
    return{
      products:[],
      pages:[],
      data:[],
      langcontent: [],
    }
  },
  created() {
    axios.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('contact')
        .then(response => {
          this.data = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('products')
        .then(response => {
          this.products = response.data
        })
        .catch(error => {
          console.log(error);
        })
    iso.get('pages')
        .then(response => {
          this.pages = response.data
        })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>